export default function CloseNoBackground({onDelete}) {
    return (
        <svg
            onClick={onDelete}
            className="fill-current inline-block text-[1rem] transition-[fill] shrink-0 select-none p-0 box-[unset] border-none ml-[2px] w-[18px] h-[18px] my-0 mr-2 cursor-pointer text-inherit no-highlights"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
        >
            <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
        </svg>
    )
}