import { twMerge } from 'tailwind-merge'

export default function Button({ onClick, text, className, disabled = false, dataTest = null, type = 'submit' }) {
    return (
        <button
            data-test={dataTest}
            disabled={disabled}
            onClick={onClick}
            type={type}
            className={twMerge(`tracking-wider text-white text-base relative rounded-full border-0 transition duration-100 ${disabled ? 'bg-[#a4bde8] cursor-not-allowed' : 'bg-secondary-blue hover:bg-[#6c9df1] cursor-pointer'} p-4 py-1.5 text-left shadow-sm focus:outline-none sm:text-sm sm:leading-6`, className)}
        >
            <span className="flex justify-center items-center">
                <span className={`flex justify-center items-center text-inherit truncate tracking-[inherit] font-bold font-['Nunito'] uppercase`}>{text}</span>
                <span className={'top-0 left-0 right-0 bottom-0 z-0 overflow-hidden absolute border-inherit pointer-events-none'}></span>
            </span>
        </button>
    )
}

