import { useEffect } from 'react'
import PropTypes from "prop-types";
import CloseNoBackground from './vectors/CloseNoBackground';

const PopUpNotification = ({ open, handleClose, message, severity }) => {
    useEffect(() => {
        if (!open) {
            return;
        }

        const timeout = setTimeout(() => {
            handleClose()
        }, 5000)
        
        return () => {
            clearTimeout(timeout)
        }
    }, [open])

    if (!open) {
        return null
    }

    return (
        <div className='flex z-[1400] items-center justify-center bottom-6 fixed left-0 right-0 overflow-hidden'>
            <div className={`${severity === 'warning' ? 'bg-warning' : 'bg-mint-green'} p-5 pt-[1.3rem] rounded-md flex`}>
                <p className='text-lg m-0 font-bold'>
                    {message}
                </p>

                <span className='mt-1 ml-2 [&_svg]:h-5'>
                    <CloseNoBackground onDelete={handleClose} />
                </span>
            </div>
        </div>
    )
}

const propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    severity: PropTypes.string.isRequired,
}

PopUpNotification.propTypes = propTypes

export default PopUpNotification;
