import cn from 'classnames';
import Header from '../components/Header/Header';
import Footer from '../components/Footer';
import HeroImageSection from '../components/HeroImageSection';
import { connect } from "react-redux";
import { resetNotification } from "../actions/notificationActions";
import PopUpNotification from "../components/PopUpNotification";
import Image from 'next/image';
import { homepageHeroBackground } from '../assets/images';

function PageWrapper({ headerComponent, noPadding, hideHeader, hero, homepage, pathname, children, containerStyle, showNotification, resetNotification, ...rest }) {
    const renderHeader = () => {
        if (pathname === '/') {
            return (
                <div className="relative">
                    <Header {...rest} />
                    <div className={'bg-primary-blue'}>
                        <Image
                            src={homepageHeroBackground}
                            alt='Hero section background image'
                            objectFit='cover'
                            className='max-md:!hidden'
                            objectPosition='center'
                            layout='fill'
                            priority
                        />
                        <HeroImageSection />
                    </div>
                </div>
            );
        }

        return <Header hidden={hideHeader} {...rest} />;
    }

    const mainClass = noPadding ? containerStyle : cn('relative z-[1] pt-5 px-10 pb-0 max-sm:pt-0 max-sm:px-2', containerStyle);

    return (
        <>
            <div className='max-w-[1440px] m-auto'>
                { renderHeader() }
                <main id="site-content" className={mainClass}>
                    { children }
                </main>

                <PopUpNotification
                    open={showNotification}
                    message="Please check you have completed all fields."
                    handleClose={() => resetNotification()}
                    severity={"warning"}
                />

                <Footer />
            </div>
        </>
    )
}

const mapStateToProps = ({ notification }) => {
    return {
        showNotification: notification?.showNotification,
    }
}

export default connect(mapStateToProps, { resetNotification })(PageWrapper);
